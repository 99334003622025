import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  { path: 'offers', loadChildren: () => import('./screens/offers/offers.module').then(m => m.OffersModule), canActivate: [authGuard(true)] },
  { path: '', loadChildren: () => import('./screens/locations/locations.module').then(m => m.LocationsModule), canActivate: [authGuard(true)] },
  { path: '', loadChildren: () => import('./screens/profile/profile.module').then(m => m.ProfileModule), canActivate: [authGuard(true)] },

  { path: '', loadChildren: () => import('./screens/login/login.module').then(m => m.LoginModule),canActivate: [authGuard(false)] },
  
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./screens/onboarding/onboarding.module').then(m => m.OnboardingModule),canActivate: [authGuard(false)] },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
