import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../../../../services/contentful.service';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrl: './terms-conditions.component.less',
})
export class TermsConditionsComponent implements OnInit {
  TnCCMSData: any;
  locale = 'en-US';
  labelsCMSModelData: any;
  TnCData: any = [];
  contentFulIds: any = [];
  getTnCIds: any = [];
  getTnCDetails: any = [];
  openSections = [true, false];
  backgroundURL: any;

  constructor(
    private contentfulService: ContentfulService,
    private sharedService: SharedService
  ) {

  }

  ngOnInit(): void {
    this.backgroundURL = '';
    this.getTnCCMSData();
  }

  toggleSection(index: number): void {
    this.openSections[index] = !this.openSections[index];
  }

  getTnCCMSData() {
    // this.sharedService.toggleGlobalLoader(true);
    this.contentfulService
      .getContent('2Inrc4GEzsvSsoz1EFXAJU', this.locale)
      .subscribe((data: any) => {
        this.TnCCMSData = data;
        this.backgroundURL = this.TnCCMSData.programTnCBackgroundImage.fields.file.url;
        this.TnCData = this.TnCCMSData.programTnCSection;
        this.TnCData.forEach((element: any) => {
          this.getTnCIds.push(element.sys.id);
        });

        this.getAllTnCData(this.getTnCIds);
        this.sharedService.toggleGlobalLoader(false);
      });
  }

  getAllTnCData(contentIDs: any) {
    this.sharedService.toggleGlobalLoader(true);
    contentIDs.forEach((ele: any) => {
      this.contentfulService
        .getContent(ele, this.locale)
        .subscribe((data: any) => {
          this.getTnCDetails.push(data);
          this.sharedService.toggleGlobalLoader(false);
        });
    });
  }
}
