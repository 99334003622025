import {
  CanActivateFn,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { inject } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { LoginService } from '../../screens/login/login.service';

export const authGuard =
  (isProtected: boolean): CanActivateFn =>
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router: Router = inject(Router);
    const authenticationService = inject(AuthenticationService);
    const loginService = inject(LoginService);
    // const protectedRoutes: string[] = ['/locations','/offers','/profile'];
    // Initialize getCurrentUser as false by default
    let isAuthenticated = false;
    let passwordScNumber: number | null = null;

    // Check if the platform is a browser and localStorage is available

      const storedUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
      isAuthenticated = !!storedUser && (storedUser.token || storedUser["Two-FA-Token"]); // Ensure getCurrentUser is true if user exists and has a token

      const tenantDetail = JSON.parse(localStorage.getItem('tenantInfo') || '{}');
      const isTwofaSet = JSON.parse(localStorage.getItem('is_2fa_setup_completed') || '{}');
      const isTotpView = JSON.parse(localStorage.getItem('showTOTPSection') || '{}');
      const pwdScreenNo = JSON.parse(localStorage.getItem('password_screen_number') || '{}');

    if (isAuthenticated && !isProtected) {

      if(isTotpView) {
        authenticationService.logoutUser();
        return false;
      }
      if(storedUser.is_2fa_setup && isTwofaSet) {
        router.navigate(['/offers']);
      } else {
        authenticationService.logoutUser();
        return false;
      }
      if(tenantDetail['2fa_status'] == 'MANDATORY' && isTwofaSet == false) {
        authenticationService.logoutUser();
        return false;
      } else if(tenantDetail['2fa_status'] == 'OPTIONAL') {
        router.navigate(['/offers']);
      }
    } else if (!isAuthenticated && isProtected) {
      router.navigate(['/login']);
    } else if(!isAuthenticated && !isProtected && pwdScreenNo != 0) {
      localStorage.setItem('password_screen_number', '0');
      router.navigate(['/login']);
      return false;
    } else {
      return true;
    }
    return false;
  };
