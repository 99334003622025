import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../services/authentication.service';
import { LoginService } from '../../../screens/login/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.less'
})
export class HeaderComponent implements OnInit {

  currentUser: any;
  isLogin: boolean = false;

  constructor(
    private authenticationService: AuthenticationService, 
    private router:Router,
  ) {}

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(data => {
      this.currentUser = data;
      if(this.currentUser && (this.currentUser.token || this.currentUser["Two-FA-Token"]) && JSON.parse(localStorage.getItem('showTOTPSection') || '{}') == false) {
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }
    });
  }

  logout() {
    this.authenticationService.logoutUser();
  }

  routeToLogin() {
    if(this.isLogin) {
      this.router.navigate(['/offers']);
    } else {
      this.router.navigate(['/login']);
    }
  }

}
