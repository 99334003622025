import { Component, Input } from '@angular/core';
import { SharedService } from '../../../../../../shared/shared.service';
import { CommonModule } from '@angular/common';
import { LibraryModule } from '../../../../../../library/library.module';
@Component({
  selector: 'app-invoice-transactions-details',
  templateUrl: './invoice-transactions-details.component.html',
  styleUrl: './invoice-transactions-details.component.less',
  standalone: true,
  imports: [CommonModule,LibraryModule],
})
export class InvoiceTransactionsDetailsComponent {
  @Input() transactionDetails: any = [];
  currencySymbol = '';
  getGenericLabels: any;

  constructor(
    private sharedService: SharedService
  ) {

  }
  ngOnInit(): void {
    this.getGenericLabels = JSON.parse(localStorage.getItem('genericLabels') || '{}');
    console.log('this.getGenericLabels',this.getGenericLabels);
    this.currencySymbol = this.sharedService.getCurrencyCode;
  }
}
