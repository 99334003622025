<div class="page-container">
  <app-header class="header"></app-header>

  <div class="main-content">
    <aside *ngIf="showSideStepper" class="side-stepper-container">
      <app-side-stepper [activeStep]="activeStep"></app-side-stepper>
    </aside>
    <main class="content">
      <router-outlet></router-outlet> <!-- Main content will be rendered here -->
    </main>
  </div>

  <app-footer class="footer"></app-footer>
</div>