<div class="invoice-listing">
  <div class="invoice-listing__labels">
    <span></span>
    <span>{{getGenericLabels?.BITID}}</span>
    <span>{{getGenericLabels?.MemberID}}</span>
    <span>{{getGenericLabels?.DateTime}}</span>
    <span>{{getGenericLabels?.OfferReference}}</span>
    <span>{{getGenericLabels?.Outlet}}</span>
    <span>{{getGenericLabels?.Points}}</span>
    <span class="invoice-listing__labels__align-right overflow"
      >{{getGenericLabels?.Amount}} ({{currencySymbol}})</span
    >
    <span></span>
  </div>
  <div
    class="invoice-listing__layout"
    *ngFor="let transaction of transactionDetails"
  >
    <span></span>
    <span class="overflow" title="{{ transaction?.bit_reference }}">{{
      transaction?.bit_reference
    }}</span>
    <span class="overflow" title="{{ transaction?.member_id }}">{{
      transaction?.member_id
    }}</span>
    <span class="overflow" title="{{ transaction?.h_bit_date }}">{{
      transaction?.h_bit_date
    }}</span>
    <span class="overflow" title="{{ transaction?.bonus_offer?.offer_name }}">{{
      transaction?.bonus_offer?.offer_name
    }}</span>
    <span class="overflow" title="{{ transaction?.h_location_name }}">{{
      transaction?.h_location_name
    }}</span>
    <span class="overflow" title="{{ transaction?.rewardArraySum | addComma }}">{{
      transaction?.rewardArraySum | addComma
    }}</span>
    <span
      class="invoice-listing__layout__align-right"
      title="{{ transaction?.h_bit_amount | addComma }}"
      >{{ transaction?.h_bit_amount | addComma }}</span
    >
    <span></span>
  </div>
</div>
