import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentsService } from '../../services/payments.service';
import { SharedService } from '../../../../shared/shared.service';
import { LibraryModule } from '../../../../library/library.module';
import { InvoiceTransactionsDetailsComponent } from './components/invoice-transactions-details/invoice-transactions-details.component';
import dayjs from 'dayjs';
import { LibraryService } from '../../../../library/lib/services/library.service';
import { DateRangePicker } from '../../../../library/lib/interfaces/date-range-picker.interface';
import { forkJoin } from 'rxjs';
import { CommonModule } from '@angular/common';
import { DateTimeHelper } from '../../../../shared/helpers/datetime.helper';
import { BITHelper } from '../../../../shared/helpers/bit.helper';
import { ContentfulService } from '../../../../../services/contentful.service';
@Component({
  selector: 'app-invoice-details',
  standalone: true,
  imports: [LibraryModule, InvoiceTransactionsDetailsComponent, CommonModule],
  templateUrl: './invoice-details.component.html',
  styleUrl: './invoice-details.component.less',
})
export class InvoiceDetailsComponent {
  invoiceId: any;
  transactionDetails: any = [];
  previousPage: any;
  nextPage: any;
  sponsorDetails: any = {};
  invoiceData: any;
  currencySymbol: any = '';
  isGlobalLoaderOn: boolean = true;
  showloader: boolean = true;
  error: boolean = false;
  errorMsg: any;
  showtooltip:boolean=false;
  loyaltyAccounts:any;
  paymentCMSData: any;
  locale = 'en-US';
  getGenericLabels: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private paymentsService: PaymentsService,
    private sharedService: SharedService,
    private libraryService: LibraryService,
    private contentfulService: ContentfulService,
  ) {}
  ngOnInit(): void {
    this.getGenericLabels = JSON.parse(localStorage.getItem('genericLabels') || '{}');
    this.route.paramMap.subscribe((params) => {
      this.invoiceId = params.get('id');
      this.getData();
      this.getPaymentCMSData();
    });
    this.sponsorDetails = this.sharedService.sponsorDetails;
    this.currencySymbol = this.sharedService.getCurrencyCode;
  }
  getData() {
    this.sharedService.toggleGlobalLoader(true);
    this.isGlobalLoaderOn = true;
    this.showloader = true;
    forkJoin([
      this.paymentsService.getInvoiceDetails(
        this.invoiceId,
        this.sharedService.currentSponsorId
      ),
      this.paymentsService.getInvoiceTransactions(
        undefined,
        this.invoiceId,
        this.sharedService.currentSponsorId
      ),
      this.sharedService.getLoayltyAccounts()
    ]).subscribe({
      next: (data: any) => {
        this.isGlobalLoaderOn = false;
        this.showloader = false;
        this.sharedService.toggleGlobalLoader(false);
        this.invoiceData = data[0];
        this.transactionDetails = data[1].data;
        this.previousPage = data[1].prev!='None' ? data[1].prev : undefined;
        this.nextPage = data[1].next!='None' ? data[1].next : undefined;
        this.processInvoiceData();
        this.loyaltyAccounts = data[2];
        this.sharedService.loyaltyAccounts=this.loyaltyAccounts;
        this.processTransaction();
      },
      error: (error: any) => {
        this.isGlobalLoaderOn = false;
        this.showloader = false;
        this.error = true;
        this.errorMsg = error.error;
        this.sharedService.toggleGlobalLoader(false);
      },
    });
  }
  getInvoiceDetails() {
    this.paymentsService
      .getInvoiceDetails(this.invoiceId, this.sharedService.currentSponsorId)
      .subscribe({
        next: (data: any) => {
          this.invoiceData = data;
          this.processInvoiceData();
        },
        error: (error: any) => {
          console.log(error);
        },
      });
  }
  processInvoiceData() {
    this.invoiceData.issue_date = this.invoiceData.issue_date
      ? dayjs(this.invoiceData.issue_date).format(DateTimeHelper.dateFormat)
      : '';
    this.invoiceData.invoicing_period =
      dayjs(this.invoiceData.start_date).format(DateTimeHelper.dateFormat) +
      ' - ' +
      dayjs(this.invoiceData.end_date).format(DateTimeHelper.dateFormat);
    this.invoiceData.total_points = 0;
    if (Array.isArray(this.invoiceData.points)) {
      this.invoiceData.points.forEach((pointData: any) => {
        this.invoiceData.total_points += pointData.points;
      });
    }
  }

  getInvoiceTransactions(cursor: any) {
    this.showloader = true;
    this.paymentsService
      .getInvoiceTransactions(
        cursor,
        this.invoiceId,
        this.sharedService.currentSponsorId
      )
      .subscribe({
        next: (data: any) => {
          this.showloader = false;
          this.transactionDetails = data.data;
          this.previousPage = data.prev;
          this.nextPage = data.next;
          this.processTransaction();
        },
        error: (error: any) => {
          this.showloader = false;
          this.error = true;
          this.errorMsg = error.error;
        },
      });
  }
  processTransaction() {
    this.transactionDetails.forEach((transaction: any) => {
      transaction.h_bit_date = transaction.h_bit_date
        ? dayjs(transaction.h_bit_date).format(DateTimeHelper.dateTimeFormat)
        : '';
      transaction['base_offer'] = {};
      transaction['bonus_offer'] = {};
      transaction['base_value'] = 0;
      transaction['bonus_value'] = 0;
      transaction.offer_actions.forEach((offer_action: any) => {
        if (
          offer_action.reward_tags !== null &&
          offer_action.reward_tags[0] === 'BASE'
        ) {
          transaction['base_offer'] = offer_action;
          transaction['base_value'] += offer_action.value_n;
        }
        if (
          offer_action.reward_tags !== null &&
          offer_action.reward_tags[0] === 'BONUS'
        ) {
          transaction['bonus_offer'] = offer_action;
          transaction['bonus_value'] += offer_action.value_n;
        }
      });
    });
    this.transactionDetails=BITHelper.addCustomInfoToMemberBits(this.transactionDetails,  this.loyaltyAccounts);
  }
  handlePagination(event: any) {
    this.getInvoiceTransactions(event);
  }
  goToPaymentListing() {
    this.router.navigate([`/payments`]);
  }
  downloadTransactions(fileType: string) {
    this.showtooltip=false;
    this.sharedService.toggleGlobalLoader(true);
    this.paymentsService
    .downloadInvoiceTranscations(
      this.invoiceId,
      this.sharedService.currentSponsorId,
      fileType
    )
    .subscribe({
      next: (data: any) => {
        this.sharedService.toggleGlobalLoader(false);
        this.downLoadFile(data);
      },
      error: (error: any) => {
        this.sharedService.toggleGlobalLoader(false);
        this.error = true;
        this.errorMsg = error.error;
      },
    });
  }
  downLoadFile(data: any) {
    let a = document.createElement('a');
    a.href = data.url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  downloadingTransactions(type: string, start_date: string, end_date: string) {
    this.paymentsService
      .downloadTransactions(
        this.sharedService.currentSponsorId,
        start_date,
        end_date,
        type,
        undefined
      )
      .subscribe({
        next: (data: any) => {
          this.downLoadFile(data);
        },
        error: (error: any) => {
          this.error = true;
          this.errorMsg = error.error;
        },
      });
  }
  downloadInvoice() {
    this.sharedService.toggleGlobalLoader(true);
    this.paymentsService
      .downloadPartnerInvoice(
        this.invoiceId,
        this.sharedService.currentSponsorId
      )
      .subscribe({
        next: (data: any) => {
          this.sharedService.toggleGlobalLoader(false);
          this.downLoadFile(data);
        },
        error: (error: any) => {
          this.sharedService.toggleGlobalLoader(false);
          this.error = true;
          this.errorMsg = error.error;
        },
      });
  }

  getPaymentCMSData() {
    this.contentfulService.getContent('3X2w0tubI7EQPAGOdBXVSR', this.locale).subscribe((data: any) => {
        this.paymentCMSData = data;
      });
  }
}
