<div class="header-container">
    <div class="header-content">
        <div class="header-left-content">
            <img class="sponsor-image" src="assets/images/icons/sponsor_logo.png" alt="sponsor-logo" (click)="routeToLogin()">
            <div class="sponsor-header">ULTA BEAUTY</div>
            <div class="powered-header">powered by</div>
            <div class="gravty-header">GRAVTY</div>
        </div>
        <div class="header-right-content" *ngIf="isLogin">
            <span><img class="bell-icon" src="assets/images/icons/bell_outline.png" alt="bell-icon"></span>
            <span><img class="settings-icon" src="assets/images/icons/settings_icon.png" alt="settings-icon"></span>
            <span (click)="logout()"><img class="profile-icon" src="assets/images/icons/header_person_logo.png" alt="profile-icon"></span>
        </div>
    </div>
</div>
